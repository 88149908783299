import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICommerceApiSettings,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { ChannelIdentity } from '@msdyn365-commerce/retail-proxy';
import { getChannelsAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';

interface IChannelIdentity {
    channelIdentities: ChannelIdentity[];
}

/**
 * Calls the Retail API and returns the channels
 */
const getChannels = () => async (ctx: IActionContext): Promise<ChannelIdentity[]> => {
    return getChannelsAsync(
        {
            callerContext: ctx
        }
    );
};

/**
 *  Action input
 */
export class GetChannelIdentitiesInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`ChannelIdentities`, this.apiSettings);
    public getCacheObjectType = () => `ChannelIdentities`;
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createChannelIdentitiesInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new GetChannelIdentitiesInput(inputData.requestContext.apiSettings);
};

/**
 * Get channels action
 */
export async function getChannelsAction(
    input: GetChannelIdentitiesInput,
    ctx: IActionContext
): Promise<IChannelIdentity> {
    if (!ctx) {
        throw new Error(`getChannelsAction - Action context cannot be null/undefined`);
    }

    let channels: ChannelIdentity[] = [];

    try {
        channels = await getChannels()(ctx);
    } catch (error) {
        throw error;
    }

    return { channelIdentities: channels };
}

export const getChannelsActionDataAction =  createObservableDataAction({
    id: '@msdyn365-commerce-modules/order-management/get-channels',
    action: <IAction<IChannelIdentity>>getChannelsAction,
    input: createChannelIdentitiesInput
});

export default getChannelsActionDataAction;
