import { buildCacheKey } from '@msdyn365-commerce-modules/retail-actions';
import {
    CacheType,
    createObservableDataAction,
    IAction,
    IActionContext,
    IActionInput,
    IAny,
    ICommerceApiSettings,
    ICreateActionContext,
    IGeneric
} from '@msdyn365-commerce/core';
import { OrgUnitLocation, SearchArea } from '@msdyn365-commerce/retail-proxy';
import { getOrgUnitLocationsByAreaAsync } from '@msdyn365-commerce/retail-proxy/dist/DataActions/OrgUnitsDataActions.g';

interface IOrgUnitLocations {
    orgUnitLocations: OrgUnitLocation[];
}

const searchArea: SearchArea = {
    Radius: Math.max(),
    DistanceUnitValue: 0 // 0 is miles
};

/**
 * Calls the Retail API and returns the orgUnitLocations
 */
const getOrgUnitLocations = () => async (ctx: IActionContext): Promise<OrgUnitLocation[]> => {
    return getOrgUnitLocationsByAreaAsync(
        {
            callerContext: ctx,
            queryResultSettings: {}
        },
        searchArea
    );
};

/**
 *  Action input
 */
export class GetOrgUnitLocationsInput implements IActionInput {
    private apiSettings: ICommerceApiSettings;

    constructor(apiSettings: ICommerceApiSettings) {
        this.apiSettings = apiSettings;
    }

    public getCacheKey = () => buildCacheKey(`OrgUnitLocations`, this.apiSettings);
    public getCacheObjectType = () => `OrgUnitLocations`;
    public dataCacheType = (): CacheType => 'request';
}

/**
 * Creates the input required to make the retail api call
 */
const createOrgUnitLocationsInput = (inputData: ICreateActionContext<IGeneric<IAny>>) => {
    return new GetOrgUnitLocationsInput(inputData.requestContext.apiSettings);
};

/**
 * Get orgUnitLocations action
 */
export async function getOrgUnitLocationsAction(
    input: GetOrgUnitLocationsInput,
    ctx: IActionContext
): Promise<IOrgUnitLocations> {
    if (!ctx) {
        throw new Error(`getOrgUnitLocationsAction - Action context cannot be null/undefined`);
    }

    let orgUnitLocations: OrgUnitLocation[] = [];

    try {
        orgUnitLocations = await getOrgUnitLocations()(ctx);
    } catch (error) {
        throw error;
    }

    return { orgUnitLocations: orgUnitLocations };
}

export const getOrgUnitLocationsActionDataAction =  createObservableDataAction({
    id: '@msdyn365-commerce-modules/order-management/get-org-unit-locations',
    action: <IAction<IOrgUnitLocations>>getOrgUnitLocationsAction,
    input: createOrgUnitLocationsInput
});

export default getOrgUnitLocationsActionDataAction;