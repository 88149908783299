import { createObservableDataAction, IAction } from '@msdyn365-commerce/core';
import { retailAction } from '@msdyn365-commerce/retail-proxy/dist';
import { createGetTenderTypesInput } from '@msdyn365-commerce/retail-proxy/dist/DataActions/StoreOperationsDataActions.g';
import { TenderType } from '@msdyn365-commerce/retail-proxy/dist/Entities/CommerceTypes.g';

/**
 * Get Tender Types Data Action
 */
export const retailActionDataAction =  createObservableDataAction({
    action: <IAction<TenderType[]>>retailAction,
    input: () => createGetTenderTypesInput({})
});

export default retailActionDataAction;